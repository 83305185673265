<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider vid="first_last_name" name="Primer Apellido" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="first_last_name">Primer Apellido</label>
              <input type="text" v-model="user.first_last_name"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="first_last_name" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider vid="second_last_name" name="Segundo Apellido" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="second_last_name">Segundo Apellido</label>
              <input type="text" v-model="user.second_last_name"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="second_last_name" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider vid="name" name="Nombres" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="name">Nombres</label>
              <input type="text" v-model="user.name"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="name" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider vid="document_type" name="Tipo de Documento" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="document_type">Tipo de Documento</label>
              <select v-model="user.document_type" id="document_type"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')">
                <option>CEDULA</option>
                <option>PASAPORTE</option>
              </select>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider vid="document_number" name="Número de Documento" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="document_number">Número de Documento</label>
              <input type="text" v-model="user.document_number"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="document_number" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors }">
            <div class="form-group">
              <label for="email">Correo Electrónico</label>
              <input type="email" v-model="user.email"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="email" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" v-model="user.password"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="password" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider vid="password_confirmation" name="Confirmar Contraseña" rules="required"
            v-slot="{ errors }">
            <div class="form-group">
              <label for="password_confirmation">Confirmar Contraseña</label>
              <input type="password" v-model="user.password_confirmation"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="password_confirmation" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>

      <div class="d-inline-block w-100">
        <span class="dark-color d-inline-block line-height-2">
          ¿Ya tienes cuenta?
          <router-link to="/dark/auth/sign-in" class="iq-waves-effect pr-4" v-if="$route.meta.dark">
            Iniciar Sesión
          </router-link>
          <router-link to="/auth/sign-in" class="iq-waves-effect pr-4" v-else>
            Iniciar Sesión
          </router-link>
        </span>
        <button type="submit" class="btn btn-primary float-right">Registrarse</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Toast } from '../../../../mixins/toast'
import api from '../../../../services/api'

export default {
  name: 'SignUp1Form',
  props: ['formType'],
  computed: {},
  data: () => ({
    user: {
      first_last_name: null,
      second_last_name: null,
      name: null,
      document_type: null,
      document_number: null,
      email: null,
      password: null,
      password_confirmation: null
    }
  }),
  methods: {
    async onSubmit () {
      await this.register()
    },
    async register () {
      try {
        await api.post('/patients/public/register', this.user)
        // this.$router.push({ name: 'auth.sign-in' })
        await this.login()
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    async login () {
      try {
        const { data } = await api.post('/login', {
          type: 'Paciente',
          document_number: this.user.document_number,
          email: this.user.email,
          password: this.user.password
        })
        const { user, token, permissions } = data.data

        localStorage.setItem('isLoggedIn', true)
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        localStorage.setItem('permissions', JSON.stringify(permissions))

        const roleMapping = {
          Doctor: 'dashboard.home-4',
          Paciente: 'dashboard.home-3'
        }

        const defaultRoute = roleMapping[user.role] || 'dashboard.home-1'
        this.$router.push({ name: defaultRoute })
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    }
  }
}
</script>
